@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&family=Uchen&display=swap');


.App {
  text-align: center;
}

body:after {
  content: '';
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 60px 30px #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2 {
  text-transform: uppercase;
}

/* Header Component */

.header__logo .material-symbols-outlined:hover {
  opacity: 0;
}

.header__logo:hover {
  background-size: 200px 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/developer.svg');
}

.header__container {
  background: rgba(0, 0, 0, 0.9) url('https://images.unsplash.com/photo-1625535069654-cfeb8f829088?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  /* background-image: url('https://images.unsplash.com/photo-1625535069654-cfeb8f829088?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80'); */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  
}

.material-symbols-outlined {
  font-size: 200px;
  color: #fff;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.header__name {
  margin: 0;
  font-family: 'Square Peg', cursive;
  font-size: 50px;
}

.header__name:hover ~ hr{
  width: 180px;
  
}

hr {
  width: 0px;
  height: 3px;
  background: #fff;
  border: #fff;
  margin: 0px;
  transition: width 450ms ease-out;
}

.header__position {
  font-family: 'Uchen', serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.header__name, .header__position {
  color: #fff;
}

/* About Component */
.about {
  max-height: 100%;
  padding: 50px 10px; 
}

.about__text p {
  text-align: left;
}

.about__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  margin: 50px auto;
}

.about__introduction {
  justify-content: center;
  flex: 2;
  padding: 0 20px;
}

h2.about__heading {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 30px;
  border-top: 5px solid #666666;
  width: 100%;
  padding-top: 5px;
  display: inline;
  color: #666666;
}

/* .about__card p {
  

} */

.about__photo {
  justify-content: center;
  flex: 1;
  padding: 0 20px;
}

.about__photo img {
  max-width: 300px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
  transition: all .2s linear;
  padding: 10px;
}

.about__photo img:hover {
  filter: grayscale(100%);
}

.about__card {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 5px;
}

.about__card p {
  margin: 20px;
}

.about__card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.8);
  border-bottom: 1px solid rgba(0,0,0,.125);
  display: flex;
}

.about__card-header span {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 8px;
  background-color: antiquewhite;
}

.about__card-header span:first-child{
  background-color: #ED4C5D;
}

.about__card-header span{
  background-color: #F9CB58;
}

.about__card-header span:last-child{
  background-color: #78B159;
}

@media only screen and (max-width: 767px) {
  .about__wrapper {
    flex-direction: column;
    width: 100%;
  }
  .about__photo, .about__introduction {
    padding: 40px 0;
  }

  .about__photo img {
    max-width: 90%;
  }

  .skills {
    padding: 0 10px;
  }

  .skills__experience--list, .skills__know--list {
    padding: 0;
  }

  .skills .skill {
    font-size: 30px;
    width: 50%;
    
  }

  .skills .skill__text {
    font-size: 14px;
    margin: 12px auto;
  }

  .timeline .timeline__name {
    text-align: left;
    font-size: medium;
  }





  .timeline .timeline__container:nth-child(even) .timeline__year, .timeline .timeline__container:nth-child(even) .timeline__name, .timeline .timeline__container:nth-child(even) .timeline__description,
  .timeline .timeline__container:nth-child(odd) .timeline__year, .timeline .timeline__container:nth-child(odd) .timeline__name, .timeline .timeline__container:nth-child(odd) .timeline__description {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
  }





  .timeline .timeline__verticalline { 
    left: 35px;
    height: 40%;
  }

  .timeline .timeline__container:nth-child(even) .timeline__icon, 
  .timeline .timeline__container:nth-child(odd) .timeline__icon {
    margin: 0;
  }

  .timeline .timeline__container:nth-child(even), 
  .timeline .timeline__container:nth-child(odd) {
    grid-template-areas: 'image       ...'
                        'year        year'
                        'name        name'
                        'description description'
                        'description description';
    max-width: 70%;
    margin: 30px 0;
    left: 3.5rem;
    height: 100%;
                      }


  .projects .project {
    box-shadow: 0px 0px 0px 5px #b4b4b4 inset;
    opacity: 1;
  }

  .projects__container  {
    padding: 0 20px;
  }
}

/* Project Component */

.projects {
  background-color: #000;
  background: rgba(0, 0, 0, 0.8) url('https://images.unsplash.com/photo-1586776977607-310e9c725c37?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
  
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  padding: 50px 0;
}

.projects__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 70px;
  margin: 50px 0;
}

.projects__heading {
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  border-top: 5px solid #ccc;
  width: 100%;
  padding-top: 5px;
  display: inline;
  color: #ccc;
}

.project:hover {
  opacity: 1;
  transform: scale(1.05);
  transition: all .2s ease-in-out;
}

.project {
  /* box-shadow:0px 0px 0px 10px #b4b4b4 inset; */
  transition: all .2s ease-in;
  width: 400px;
  min-height: 400px;
  border-radius: 20px;
  background-color: #fff;
  opacity: 0.8;
  /* box-shadow: 5px 5px 5px 0px #88888875;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%); */
}

.project .project__link {
  text-decoration: none;
}

.project__text {
  text-align: left;
  margin: 20px 30px;
  color: #333;
}

.project__skill {
  display: inline-flex;
  background-color: #333;
  color: #fff;
  border-radius: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 5px 10px;
}


.project__img {
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  opacity: 0.7;
  border-radius: 20px 20px 0 0;
  width: 100%;
  object-fit: cover;
  height: 250px;
}

/* Skills Component */

.skills {
  padding: 50px 10px;
}

.skills__heading {
  text-transform: uppercase;
  font-size: 30px;
  border-top: 5px solid #666666;
  width: 100%;
  padding-top: 5px;
  display: inline;
  color: #666666;

}

.skills__experience--list, .skills__know--list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 30px auto;
  flex-wrap: wrap;
}

.skills__experience, .skills__know {
  margin: 50px auto;
}

.skills__text {
  font-size: 22px;
  color: #b1a9a9;
  width: 100%;
  display: inline;
}

.skills__divider {
  background: #39D1B4;
}

hr.skills__divider{
  border: 1px #39D1B4;
  height: 1px;
  width: 10%;
  position: relative;
  margin: 30px auto;

}

.skills__divider::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #39D1B4;
  display: inline-block;
  border: 2px solid #39D1B4;
  position: absolute;
  top: -5px;
  left: 50%;
  margin: 0 0 0 -3px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.skill {
  display: flex;
  flex-direction: column;
  width: 15%;
  font-size: 60px;
  margin: 20px auto;

}

.skill__text {
  font-size: 20px;
  margin: 20px auto;
  font-weight: 200;
}

/* Timeline */

.timeline {
  color: #fff;
  background-color: #000;
  background: rgba(0, 0, 0, 0.95) url('https://images.unsplash.com/flagged/photo-1573162915835-520ee6d112b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  padding: 50px 0 50px 0;
}

.timeline h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  border-top: 5px solid #ccc;
  width: 100%;
  padding-top: 5px;
  display: inline;
  color: #ccc;
}

.timeline__container:nth-child(odd) {
  position: relative;
  z-index: 100;
  left: 8rem;
}

.timeline__container:nth-child(even) {
  position: relative;
  left: -5rem;
  z-index: 100;
  grid-template-areas: '...  name  image'
                       '...  year  image'
                       'description  description  image';
}

.timeline__container:nth-child(even) .timeline__year, 
.timeline__container:nth-child(even) .timeline__name, 
.timeline__container:nth-child(even) .timeline__description {
  text-align: right;
} 

.timeline__container {
  height: 100px;
  margin: 20px auto;
  max-width: 400px;
  display: grid;
  align-content: space-evenly;
  justify-content: flex-start;
  grid-template-areas: 'image  name  ...'
                       'image  year  ...'
                       'image  description  description';
}

.timeline__verticalline {
  height: 30%;
  position: absolute;
  border-right: 1px dotted;
  left: 50vw;
  z-index: 50;
  /* width: 1px; */
  margin-top: 10px;
  background: aliceblue;
}


.timeline__icon img{
  width: 100px;
}

.timeline__year {
  grid-area: year;
}

.timeline__description {
  grid-area: description;
}

.timeline__name {
  grid-area: name;
  font-weight: 600;
  font-size: larger;
  font-style: italic;
} 

.timeline__icon {
  grid-area: image;
  align-self: center;
  margin: auto 30px;
  background-color: rgba(0, 0, 0, 0.536);
}

.timeline__year, .timeline__name, .timeline__description {
  text-align: left;
} 

.timeline__divider {
  border-top: 2px solid;
}

.contact {
  position: sticky;
  bottom: 0;
  font-size: 20px;
  background-color: #000;
  padding: 35px 0;
  z-index: 101;
}

.contact .material-symbols-outlined  {
  text-decoration: none;
  font-size: 24px;
  vertical-align: sub;
  margin-right: 5px;
  color: #fff;
}

.contact a  {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
}

.contact a:hover {
  cursor: pointer;
  text-decoration: underline;
}